/*global $*/
/*eslint no-undef: "error"*/
export default class ButtonForm {
    classButton = 'button-form--button'

    classButtonOpen = 'active'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            let form = $(this).data('form')
            let name = $(this).data('name')

            $(`.${self.classButton}[data-form='${form}']`).removeClass(self.classButtonOpen)
            $(this).addClass(self.classButtonOpen)

            console.log(name)
        })
    }
}
