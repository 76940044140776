/*global $*/
/*eslint no-undef: "error"*/
export default class Review {
    classItem = 'review--item'
    classButton = 'review--button'

    classItemHidden = 'hidden'
    classButtonActive = 'active'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            let block = $(this).data('block')

            $(`.${self.classButton}`).removeClass(self.classButtonActive)
            $(this).addClass(self.classButtonActive)

            $(`.${self.classItem}`).addClass(self.classItemHidden)
            $(`.${self.classItem}[data-block='${block}']`).removeClass(self.classItemHidden)
        })
    }
}
