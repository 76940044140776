/*global $*/
/*eslint no-undef: "error"*/
export default class ButtonPrice {
    classItem = 'button-price--item'
    classTable = 'button-price--table'
    classButton = 'button-price--button'

    classTableHidden = 'hidden'
    classButtonActive = 'active'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            let block = $(this).data('block')

            $(this).closest(`.${self.classItem}`).find(`.${self.classButton}`).removeClass(self.classButtonActive)
            $(this).addClass(self.classButtonActive)

            $(this).closest(`.${self.classItem}`).find(`.${self.classTable}`).addClass(self.classTableHidden)
            $(this).closest(`.${self.classItem}`).find(`.${self.classTable}[data-block='${block}']`).removeClass(self.classTableHidden)
        })
    }
}
