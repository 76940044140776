/*global $*/
/*eslint no-undef: "error"*/
export default class MenuMobile {
    classItem = 'menu-mobile--item'
    classButton = 'menu-mobile--button'

    classOpen = 'open'
    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            $(this).closest(`.${self.classItem}`).toggleClass(self.classOpen)
        })
    }
}
