<template lang="pug">
.form(:class="'form-' + formName")
  .form__block(v-if="formName === 'master'" :class="{'form__block-success': sendForm}")
    .form__section.form__section-form
      .form__text(v-html="formText")

      .form__fields
        .form__field
          .input-text.input-text-none-radius-right(:class="{'input-text-focus': fieldPhone.length === 18, 'input-text-error': fieldError && v$.$invalid}")
            input.input-text__input(
              type="tel"
              v-model="fieldPhone"
              :placeholder="formFieldPhonePlaceholder"
              v-maska="['+7 (###) ##-##-##', '+7 (###) ###-##-##']"
            )

        .form__button
          .button(
            :class="{'button-background': (formType === 'top') || (formType === 'block'), 'button-background-yellow': formType === 'line'}"
            @click="getSend()"
          )
            .button__text(v-html="formButtonName")

      .form__consent
        label.consent(:class="{'consent-white': formType === 'line'}")
          input.consent__input(
            type="checkbox"
            value="consent"
            checked="checked"
            v-model="consent"
          )
          span.consent__button
          span.consent__label(v-html="formConsentText")

    .form__section.form__section-success
      h4(v-html="formSuccessTitle")
      p(v-html="formSuccessText")

  .form__block(v-if="(formName === 'master-popup') || formName === 'master-popup-crash'" :class="{'form__block-success': sendForm}")
    h3.center(v-html="formTitle" v-show="!sendForm")

    .form__section.form__section-form
      .form__fields
        .form__field
          .input-text.input-text-gray
            input.input-text__input(
              type="text"
              v-model="fieldName"
              :placeholder="formFieldNamePlaceholder"
            )

        .form__field
          .input-text.input-text-gray(:class="{'input-text-focus': fieldPhone.length === 18, 'input-text-error': fieldError && v$.$invalid}")
            input.input-text__input(
              type="tel"
              v-model="fieldPhone"
              :placeholder="formFieldPhonePlaceholder"
              v-maska="['+7 (###) ##-##-##', '+7 (###) ###-##-##']"
            )

      .form__consent
        label.consent.consent-gray
          input.consent__input(
            type="checkbox"
            value="consent"
            checked="checked"
            v-model="consent"
          )
          span.consent__button
          span.consent__label(v-html="formConsentText")

      .form__button
        .button(
          :class="{'button-background': formType === 'popup'}"
          @click="getSend()"
        )
          .button__text(v-html="formButtonName")

    .form__section.form__section-success
      h3.center(v-html="formSuccessTitle")
      p.center(v-html="formSuccessText")


</template>

<script>
/*global $*/
/*eslint no-undef: "error"*/

import useVuelidate from '@vuelidate/core'
import { required, minLength, maxLength } from '@vuelidate/validators'
import { mapActions } from 'vuex'
export default {
  name: 'AppForm',

  setup () {
    return { v$: useVuelidate() }
  },

  props: [
    "formName",

    "formSettingTitle",
    "formSettingSubject",

    "formType",
    "formTitle",
    "formText",
    "formButtonName",

    "formFieldName",
    "formFieldNamePlaceholder",
    "formFieldPhone",
    "formFieldPhonePlaceholder",
    "formFieldPage",

    "formSuccessTitle",
    "formSuccessText",

    "formConsentText",

    "formRedirect",
    "formPageId",
    "formPagePagetitle"
  ],

  data () {
    return {
      fieldName: '',
      fieldPhone: '',
      files: '',
      consent: ['consent'],
      sendForm: false,
      fieldError: false
    }
  },

  methods: {
    ...mapActions([
      'Send'
    ]),

    getSend () {
      if (this.v$.$invalid) {
        this.fieldError = true
      }

      if (!this.v$.$invalid) {
        this.sendForm = !this.sendForm

        if (this.formName === 'master') {
          this.Send({
            setting: {
              title: this.formSettingTitle,
              subject: this.formSettingSubject
            },

            fields: [
              {
                title: this.formFieldPhone,
                value: this.fieldPhone
              },
              {
                title: this.formFieldPage,
                value: this.formPagePagetitle + ' (' + this.formPageId + ')'
              },
            ],
            files: this.files
          })

          this.fieldPhone = ''
        }

        if (this.formName === 'master-popup') {
          this.Send({
            setting: {
              title: this.formSettingTitle,
              subject: this.formSettingSubject
            },

            fields: [
              {
                title: this.formFieldName,
                value: this.fieldName
              },
              {
                title: this.formFieldPhone,
                value: this.fieldPhone
              },
              {
                title: this.formFieldPage,
                value: this.formPagePagetitle + ' (' + this.formPageId + ')'
              },
            ],
            files: this.files
          })

          this.fieldName = this.fieldPhone = ''
        }

        if (this.formName === 'master-popup-crash') {
          let curProduct = $(`.button-form--button[data-form='crash']`).data('name')

          this.Send({
            setting: {
              title: this.formSettingTitle,
              subject: this.formSettingSubject
            },

            fields: [
              {
                title: this.formFieldName,
                value: this.fieldName
              },
              {
                title: this.formFieldPhone,
                value: this.fieldPhone
              },
              {
                title: this.formFieldPage,
                value: this.formPagePagetitle + ' (' + this.formPageId + ')' + '; Товар: ' + curProduct
              },
            ],
            files: this.files
          })

          this.fieldName = this.fieldPhone = ''
        }

        setTimeout(() => {
          // this.sendForm = !this.sendForm
          window.location.href = this.formRedirect
        }, 2500)
      }
    }
  },

  validations () {
    return {
      fieldPhone: {
        required,
        minLength: minLength(18),
        maxLength: maxLength(18)
      },

      consent: {
        required
      }
    }
  }
}
</script>
